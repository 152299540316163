import { Feature, FeatureCollection } from 'geojson'
import { useEffect, useState } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'
import {
  CHARTIS_SOURCES_IDS,
  getDistinctInfrapoleCentroid,
  getDistinctZPCentroid,
  MAPBOX_LAYER_IDS,
  MapDataSignal,
} from 'services'
import { useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'

interface Props {
  view: string
  mapRef: React.RefObject<MapRef>
  visibilityZP: boolean
  visibilityInfrapole: boolean
}

export default function StructureLayer({ view, mapRef, visibilityZP, visibilityInfrapole }: Props) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.structure,
    view,
    CHARTIS_SOURCES_IDS.structure,
    mapRef,
    {
      ...perimeterParam,
      libelle__ilike: '%Siège INFRAPOLE%',
    },
  )
  const [labels, setLabels] = useState<FeatureCollection>({ type: 'FeatureCollection', features: [] })
  useEffect(() => {
    if (!mapRef?.current || (!visibilityZP && !visibilityInfrapole)) return undefined
    const calculateLabels = () => {
      if (visibilityZP) {
        const lines = mapRef?.current?.getMap()
          ?.queryRenderedFeatures({ layers: [MAPBOX_LAYER_IDS.zp] }) as Feature[] || []
        setLabels(getDistinctZPCentroid(lines))
      }
      if (visibilityInfrapole) {
        const lines = mapRef?.current?.getMap()
          ?.queryRenderedFeatures({ layers: [MAPBOX_LAYER_IDS.infrapole] }) as Feature[] || []
        setLabels(getDistinctInfrapoleCentroid(lines))
      }
    }

    const clearLabels = () => setLabels({ type: 'FeatureCollection', features: [] })
    const map = mapRef.current.getMap()
    map.on('idle', calculateLabels)
    map.on('movestart', clearLabels)
    map.on('moveend', calculateLabels)

    return () => {
      map.off('idle', calculateLabels)
      map.on('moveend', calculateLabels)
      map.off('movestart', clearLabels)
    }
  }, [visibilityInfrapole, visibilityZP])

  const hoveredObjectsIds = useHoveredObjectsIds()

  const { opacity, lineFilter } = MapDataSignal

  return (
    <>
      <Source
        id={CHARTIS_SOURCES_IDS.structure}
        type="vector"
        url={url}
      >
        <Layer
          type="line"
          paint={{
            'line-color': ['case',
              ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#cc0000',
              ['get', 'zone_de_production_color'],
            ],
            'line-opacity': [
              'case',
              ['in', lineFilter.value, ['get', 'code_ligne']], 1,
              opacity,
            ],
            'line-width': 2,
          }}
          id={MAPBOX_LAYER_IDS.zp}
          source-layer={CHARTIS_SOURCES_IDS.structure}
          layout={{
            visibility: visibilityZP ? 'visible' : 'none',
            'line-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, -1],
          }}
        />
        <Layer
          type="line"
          paint={{
            'line-color': ['case',
              ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#cc0000',
              ['get', 'infrapole_color'],
            ],
            'line-opacity': [
              'case',
              ['in', lineFilter.value, ['get', 'code_ligne']], 1,
              opacity,
            ],
            'line-width': 2,
          }}
          id={MAPBOX_LAYER_IDS.infrapole}
          source-layer={CHARTIS_SOURCES_IDS.structure}
          layout={{
            visibility: visibilityInfrapole ? 'visible' : 'none',
            'line-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, -1],
          }}
        />
      </Source>
      <Source
        id="structure-labels"
        type="geojson"
        data={labels}
      >
        <Layer
          type="symbol"
          id={MAPBOX_LAYER_IDS.structureLabel}
          source="structure-labels"
          layout={{
            'text-font': ['Open Sans Regular'],
            'text-field': [
              'case',
              ['boolean', visibilityInfrapole], ['get', 'libelle'],
              ['boolean', visibilityZP], ['get', 'zone_de_production_libelle'],
              '',
            ],
            'text-size': 14,
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-offset': [0, 1],
            visibility: visibilityInfrapole || visibilityZP ? 'visible' : 'none',
            'text-allow-overlap': true,
          }}
          paint={{
            'text-color': [
              'case',
              ['boolean', visibilityInfrapole], ['get', 'infrapole_color'],
              ['boolean', visibilityZP], ['get', 'zone_de_production_color'],
              '#000000',
            ],
            'text-halo-color': ['case',
              ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
              ['get', 'color']],
            'text-halo-width': 0.5,
          }}
          minzoom={8}
          maxzoom={24}
        />
      </Source>
    </>
  )
}
