import { Feature, FeatureCollection } from 'geojson'
import { useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { CHARTIS_SOURCES_IDS, getDistinctPRICentroid, MAPBOX_LAYER_IDS, MapDataSignal } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useHoveredObjectsIds, useMapURL } from 'utils'

export default function PRILayer({ view, mapRef, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.pri,
    view,
    CHARTIS_SOURCES_IDS.pri,
    mapRef,
    perimeterParam,
  )
  const [labels, setLabels] = useState<FeatureCollection>({ type: 'FeatureCollection', features: [] })
  useEffect(() => {
    if (!mapRef?.current || !visibility) return undefined
    const calculateLabels = () => {
      const lines = mapRef?.current?.getMap()
        ?.queryRenderedFeatures({ layers: [MAPBOX_LAYER_IDS.pri] }) as Feature[] || []
      setLabels(getDistinctPRICentroid(lines))
    }

    const clearLabels = () => setLabels({ type: 'FeatureCollection', features: [] })
    const map = mapRef.current.getMap()
    map.on('idle', calculateLabels)
    map.on('movestart', clearLabels)
    map.on('moveend', calculateLabels)

    return () => {
      map.off('idle', calculateLabels)
      map.on('moveend', calculateLabels)
      map.off('movestart', clearLabels)
    }
  }, [])

  const hoveredObjectsIds = useHoveredObjectsIds()

  const { opacity, lineFilter } = MapDataSignal

  return (
    <>
      <Source
        id={CHARTIS_SOURCES_IDS.pri}
        type="vector"
        url={url}
      >
        <Layer
          type="line"
          paint={{
            'line-color': ['case',
              ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#cc0000',
              ['get', 'region_color'],
            ],
            'line-opacity': [
              'case',
              ['in', lineFilter.value, ['get', 'code_ligne']], 1,
              opacity,
            ],
            'line-width': 2,
          }}
          id={MAPBOX_LAYER_IDS.pri}
          source-layer={CHARTIS_SOURCES_IDS.pri}
          layout={{
            visibility: visibility ? 'visible' : 'none',
            'line-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, -1],
          }}
        />
      </Source>
      <Source
        id="pri-labels"
        type="geojson"
        data={labels}
      >
        <Layer
          type="symbol"
          id={MAPBOX_LAYER_IDS.priLabel}
          source="pri-labels"
          layout={{
            'text-font': ['Open Sans Regular'],
            'text-field': '{region_libelle}',
            'text-size': 14,
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-offset': [0, 1],
            visibility: visibility ? 'visible' : 'none',
            'text-allow-overlap': true,
          }}
          paint={{
            'text-color': ['get', 'region_color'],
            'text-halo-color': ['case',
              ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
              ['get', 'region_color']],
            'text-halo-width': 0.5,
          }}
          minzoom={8}
          maxzoom={24}
        />
      </Source>
    </>
  )
}
